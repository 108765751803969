import React from 'react';
import AddIcon from "@mui/icons-material/Add";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Fab, FormControl, MenuItem, Select, TextField
} from "@mui/material";
import {withStyles} from "@mui/styles";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddUserDialog(props) {
  const { classes, confirmationHandler } = props;
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [role, setRole] = React.useState('ROLE_READER');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setEmail('');
    setRole('ROLE_READER');
  };

  const handleConfirm = () => {
    confirmationHandler(name, email, role);
    handleClose();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  return (
      <div>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed', marginBottom: '1rem'}}
        >
          <AddIcon sx={{ fontSize: 24 }}/>
          Add user
        </Fab>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add user</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the new user
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    label="Name"
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus={false}
                    margin="normal"
                    id="email"
                    label="Email"
                    type="text"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <DescriptionList>
              <DescriptionTerm>Type</DescriptionTerm>
              <Description>
                <Select
                    labelId="role-select"
                    id="role-select"
                    value={role}
                    className={classes.selectEmpty}
                    onChange={handleRoleChange}
                >
                  <MenuItem value={'ROLE_READER'}>Read-only</MenuItem>
                </Select>
              </Description>
            </DescriptionList>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddUserDialog)