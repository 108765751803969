import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import {CircularProgress} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";

const styles = () => ({
  paper: {
    maxWidth: '80%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function IndicatorPaper(props) {
  const { classes, indicator } = props;

  return <Paper className={classes.paper}>
    <AppBar className={classes.toolbar}
            position="static"
            color="default"
            elevation={0}>
      <Toolbar>
        <Typography variant="h2" gutterBottom component="div">
          {indicator.name}
        </Typography>
        <div style={{position: 'absolute', right: '1rem', top: '0.2rem', display: 'flex', flexDirection: 'row'}}>
        </div>
      </Toolbar>
    </AppBar>
    <div className={classes.contentWrapper}>
      <Typography variant="body1" color="textSecondary">
        {indicator.description}
      </Typography>
    </div>
  </Paper>
}

function ProjectOutcome(props) {
  const { classes, indicatorType } = props;
  const [indicators, setIndicators] = useState([
    {
      name: 'Module Objective',
      description: 'The employability of TVET and UAS graduates and students, job seekers and self-employed people in the informal sector, especially women, is increased.'
    },
    {
      name: 'Outcome 1',
      description: 'The mechanisms for steering a demand driven UAS and TVET system in Ethiopia are improved.'
    },
    {
      name: 'Outcome 2',
      description: 'The competences of UAS and TVET staff in the public and private sectors to implement demand driven UAS education and TVET are strengthened.'
    },
    {
      name: 'Outcome 3',
      description: 'The range of labour market-oriented qualification measures for school graduates, TVET and UAS graduates and students, job seekers and informally self-employed workers is expanded.'
    },
    {
      name: 'Outcome 4',
      description: 'The commitment of the private sector in TVET and employment promotion is strengthened.'
    },
    {
      name: 'Outcome 5',
      description: 'Capacities for the implementation of digitalised vocational education and training have been strengthened.'
    },
  ]);

  const renderData = () => {

    if (!indicators) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    return (
        <>
          { indicators.filter(i => i.indicatorType === indicatorType).map((indicator) => {
            return <IndicatorPaper key={indicator.id} classes={classes} indicator={indicator}/>
          })}
        </>
    );
  };

  return renderData();
}

ProjectOutcome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectOutcome);
