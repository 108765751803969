import React from "react";

export function formatOutput(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'OUTPUT_1': return 'Output 1';
    case 'OUTPUT_2': return 'Output 2';
    case 'OUTPUT_3': return 'Output 3';
    case 'OUTPUT_4': return 'Output 4';
    case 'OUTPUT_5': return 'Output 5';
    default:
      return '';
  }
}

export function getOutputDescription(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'OUTPUT_1': return 'The mechanisms for steering a demand-driven UAS and TVET system in Ethiopia have improved.';
    case 'OUTPUT_2': return 'The competences of TVET and UAS staff in the public and private sectors to implement demand-driven TVET and UAS education are strengthened.';
    case 'OUTPUT_3': return 'The range of labour market-oriented qualification measures for school graduates, TVET and UAS graduates and students, job seekers and informally self-employed persons has been expanded.';
    case 'OUTPUT_4': return 'The commitment of the private sector to vocational education and training and employment promotion has been strengthened.';
    case 'OUTPUT_5': return 'Vocational education and training offers and processes are available in digital form.';
    default:
      return '';
  }
}