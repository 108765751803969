import React, {useContext} from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from "../Login";
import {UserContext} from "./UserProvider";
import {CircularProgress} from "@mui/material";

function ProtectedRoute({ children, tabs, ...rest }) {

  const authState = useContext(UserContext);

  const renderData = () => {

    if (authState.userDataPresent) {
      return (
          <Route {...rest}>
            {authState.user ? (
                <>
                  {children}
                </>
            ) : (
                <main style={{flex: 1, background: '#eaeff1'}}>
                  <Login />
                </main>
            )}
          </Route>
      );
    } else {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }


  };

  return renderData();
}

ProtectedRoute.defaultProps = {
  tabs: null,
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
  tabs: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
};

export default ProtectedRoute;