import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import EditIcon from '@mui/icons-material/Edit';

import InputEdit from './InputEdit';
import { ButtonWithIcon, EditableComponent, paddingForInput } from './EditableInput.parts';

const Wrapper = styled.span`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  min-height: 42px;
  display: flex;
  align-items: center;
`;

const FakeInput = styled.span`
  margin: 0px 2px;
  padding: ${props => paddingForInput(props)};
  display: inline-flex;
  border: 2px solid #F5F5F5;
  border-radius: 5px;
  min-height: ${props => props.rows ? '100px' : '42px'};
  line-height: 1;
  ${props => (!props.disabled ? 'cursor: pointer;' : '')}
  ${props => (props.fullWidth ? 'width: 100%;' : '')}
  &:hover {
    ${props => (!props.disabled ? `background-color: ${props.theme.palette.secondary.light};` : '')}
  }
  transition: ${props =>
    props.theme.transitions.create(['color', 'background-color'], {
      easing: props.theme.transitions.easing.easeInOut,
      duration: props.theme.transitions.duration.short,
    })};
`;

const FloatingButton = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
`;

const EditableInput = ({ className, disabled, value, postfix, prefix, size, fullWidth, multiline, rows, alignWithBigRow = false, ...props }) => {
  const node = useRef();

  const [editButtonVisible, setEditButtonVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const handleClickOutside = e => {
      if (node.current.contains(e.target)) {
        return;
      }
      setIsEditing(false);
      setEditButtonVisible(false);
    };

    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, editButtonVisible]);

  const handleEnableEditing = () => {
    if (!disabled) {
      setIsEditing(true);
    }
  };

  return (
      <Wrapper ref={node} style={alignWithBigRow ? {marginTop: '1.2rem'} : {}}>
        {!isEditing ? (
            <EditableComponent
                className={className}
                fullWidth={fullWidth}
                size={size}
                onMouseEnter={() => setEditButtonVisible(true)}
                onMouseLeave={() => setEditButtonVisible(false)}
            >
              {prefix}
              <FakeInput fullWidth={fullWidth} multiline={multiline} rows={rows} size={size} disabled={disabled} onClick={handleEnableEditing}>
                {value}
              </FakeInput>
              {postfix}
              {editButtonVisible && !disabled && !fullWidth && (
                  <FloatingButton>
                    <ButtonWithIcon aria-label={'Bearbeiten'} buttonsize={size} onClick={handleEnableEditing}>
                      <EditIcon />
                    </ButtonWithIcon>
                  </FloatingButton>
              )}
            </EditableComponent>
        ) : (
            <InputEdit
                fullWidth={fullWidth}
                prefix={prefix}
                postfix={postfix}
                size={size}
                onClose={() => {
                  setIsEditing(false);
                }}
                multiline={multiline}
                rows={rows}
                value={value}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        )}
      </Wrapper>
  );
};

EditableInput.defaultProps = {
  className: '',
  disabled: false,
  inputProps: {},
  postfix: '',
  prefix: '',
  type: 'string',
  size: 'large',
  value: '',
  fullWidth: false,
};

EditableInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types */
  inputProps: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  name: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  postfix: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
};

export default EditableInput;