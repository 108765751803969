import React from 'react';
import AddIcon from "@mui/icons-material/Add";
import {
  Button, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Fab,
  FormControl,
  InputLabel,
  MenuItem, Select
} from "@mui/material";
import {withStyles} from "@mui/styles";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function AddActivityDialog(props) {
  const { classes, confirmationHandler } = props;
  const [activityType, setActivityType] = React.useState('COOPERATIVE_TRAINING');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    confirmationHandler(activityType);
    handleClose();
  };

  const handleActivityTypeChange = (event) => {
    setActivityType(event.target.value);
  };

  return (
      <div>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed'}}
        >
          <AddIcon sx={{ fontSize: 24 }}/>
          Add activity
        </Fab>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add activity</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select the type of activity first
            </DialogContentText>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Type
              </InputLabel>
              <Select
                  labelId="activity-type-select"
                  id="activity-type-select"
                  value={activityType}
                  onChange={handleActivityTypeChange}
                  className={classes.selectEmpty}
              >
                <MenuItem value={'COOPERATIVE_TRAINING'}>Cooperative Training</MenuItem>
                <MenuItem value={'SHORT_TERM_TRAINING'}>Short-term Training</MenuItem>
                <MenuItem value={'FURTHER_TRAINING'}>Further Training</MenuItem>
                <MenuItem value={'LEADERSHIP_TRAINING'}>Leadership Training</MenuItem>
                <MenuItem value={'PUBLIC_PRIVATE_DIALOGUE'}>Public Private Dialogue</MenuItem>
                <MenuItem value={'POLICY_SUPPORT'}>Policy Support</MenuItem>
                <MenuItem value={'COMPANY_SUPPORt'}>Company Support</MenuItem>
                <MenuItem value={'DIGITALIZATION'}>Digitalization</MenuItem>
                <MenuItem value={'OTHER'}>Other</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" size="small">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" size="small">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddActivityDialog)