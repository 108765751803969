import React from 'react';
import {CircularProgress, Typography} from "@mui/material";


function DumpLoadingIndicator() {

  return (
      <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
        <CircularProgress style={{marginTop: '10%'}} color="primary" />
        <Typography style={{marginTop: '1rem'}} color="inherit" variant="subtitle1">Generating data. Please wait a second!</Typography>
      </div>
  );
}

export default DumpLoadingIndicator;