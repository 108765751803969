import { DateTime } from 'luxon';
import i18next from 'i18next';

const timeZone = 'Europe/Berlin';
const defaultFormat = 'dd.LL.yyyy';

export function formatTime(date, formatStr = defaultFormat) {
  if (!date) {
    return null;
  }
  const sqlDate = DateTime.fromSQL(date, { zone: 'utc' });

  return sqlDate
  .setZone(timeZone)
  .setLocale(i18next.language)
  .toFormat(formatStr);
}