import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  createInstitution, getInstitutionDump, getInstitutions, updateInstitution
} from "../../utils/api";
import {CircularProgress, Fab} from "@mui/material";
import {withStyles} from "@mui/styles";
import {DataGrid} from "@mui/x-data-grid";
import {UserContext} from "../auth/UserProvider";
import AddInstitutionDialog from "../dialogs/AddInstitutionDialog";
import DownloadIcon from "@mui/icons-material/GetApp";
import * as XLSX from "xlsx";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '3rem',
    right: '2.5rem',
  },
});


function InstitutionSettings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [userList, setUserList] = useState('');
  const [institutionBuffer, setInstitutionBuffer] = useState({});
  const [isLoadingDump, setIsLoadingDump] = useState(false);

  const authState = useContext(UserContext);

  const addConfirmationHandler =
      async (name, location) => {
        await createInstitution({
          'name': name,
          'location': location,
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, name, location) => {
        await updateLocationAndDispatch(entityId, {
          'name': name,
          'location': location,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const fetchData =
      async (loading) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getInstitutions();
          const rolesData = result.data;

          if (!cancelRequest.current) {
            if (rolesData && Object.keys(rolesData).length > 0) {
              setUserList(rolesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, []);

  const downloadInstitutionDump = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Institution list');

    let ws_data = [
      ['Id', 'Name', 'Location'],
    ];

    data.map(i => {
      ws_data.push([i.id, i.name, i.location ? i.location.englishName : '']);
    });

    wb.Sheets['Institution list'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,'institution_list.xlsx');
  };

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getInstitutionDump()
              .then((response) => {
                setIsLoadingDump(false);
                if (response.data) {
                  downloadInstitutionDump(response.data);
                }
              });
        } catch (error) {
          console.log(error);
        }
      };

  const updateLocationAndDispatch = async (locationId, fields) => {
    try {
      let buffer = {
        ...institutionBuffer
      };

      setInstitutionBuffer(buffer);

      const { data: data } = await updateInstitution(
          locationId,
          fields
      );

      if (!cancelRequest.current) {
        setInstitutionBuffer(data);
      }
    } catch (error) {
      setInstitutionBuffer(institutionBuffer);
    }
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Access denied!
            </div>
          </>
      );
    }

    if (isLoading || !userList) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <DataGrid
              hideFooter={true}
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={userList}
              columns={[
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'location', headerName: 'Location', flex: 1,
                  renderCell: (params) =>
                      params.row.location ? params.row.location.englishName : ''
                },
                {
                  field: 'actions',
                  headerName: 'Action',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <AddInstitutionDialog
                        confirmationHandler={updateConfirmationHandler}
                        action="edit"
                        entity={params.row} />;
                  }
                }
              ]}
              density="compact"
              rowCount={userList.length}
          />
          {authState.role === 'ROLE_SUPER' &&
            <>
              <div>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => fetchDump()}
                    className={classes.fab}
                    style={{position: 'fixed', marginBottom: '0.075rem', marginRight: '14rem' }}
                >
                  <DownloadIcon/>
                </Fab>
              </div>
              <AddInstitutionDialog
                  confirmationHandler={addConfirmationHandler}/>
            </>
          }
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(InstitutionSettings);