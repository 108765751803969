import React, {useEffect, useRef, useState} from 'react';
import {
  CircularProgress,
  Grid,
  Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Typography
} from "@mui/material";
import {withStyles} from "@mui/styles";
import * as PropTypes from "prop-types";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {Icon} from "leaflet";
import {getStatistics} from "../../utils/api";
import {formatTime} from "../../utils/timeHelper";
import {formatActivityType} from "../../utils/activityTypeHelper";
import {truncate} from "../../utils/stringHelper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter'
import Chart from "react-apexcharts";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
});

MapContainer.propTypes = {
  style: PropTypes.shape(
      {paddingTop: PropTypes.string, height: PropTypes.number}),
  scrollWheelZoom: PropTypes.bool,
  zoom: PropTypes.number,
  center: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node
};

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
  main: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
}))(LinearProgressWithLabel);

function LinearProgressWithLabel(props) {
  const { value, current, target, classes } = props;

  return (
      <Box display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress sx={{height: 12}} color="primary" variant="determinate" value={value > 100 ? 100 : value} classes={classes} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary"><strong>{`${Math.round(value)}%`}</strong></Typography>
          <Typography variant="body2" color="textSecondary">
            {current} / {target}
          </Typography>
        </Box>
      </Box>
  );
}

function Dashboard(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [statistics, setStatistics] = useState('');
  const [mapref, setMapref] = useState();

  const cancelRequest = useRef();

  useEffect(() => {
    if (mapref) {
      new SimpleMapScreenshoter().addTo(mapref);
    }
  }, [mapref]);

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getStatistics();
          const statisticsData = result.data;

          if (!cancelRequest.current) {
            if (statisticsData && Object.keys(statisticsData).length > 0) {
              setStatistics(statisticsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const position = [9.1450,	40.4897];

  const blueGizIcon = new Icon({
    iconUrl: 'markers/marker-icon-blue-giz.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const yellowGizIcon = new Icon({
    iconUrl: 'markers/marker-icon-gold-giz.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const greenGizIcon = new Icon({
    iconUrl: 'markers/marker-icon-green-giz.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const redGizIcon = new Icon({
    iconUrl: 'markers/marker-icon-red-giz.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const greenYellowIcon = new Icon({
    iconUrl: 'markers/marker-icon-green-gold.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const greenYellowGizIcon = new Icon({
    iconUrl: 'markers/marker-icon-green-gold-giz.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const blueYellowRedIcon = new Icon({
    iconUrl: 'markers/marker-icon-blue-gold-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const blueYellowRedGizIcon = new Icon({
    iconUrl: 'markers/marker-icon-blue-gold-red-giz.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const yellowRedIcon = new Icon({
    iconUrl: 'markers/marker-icon-gold-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const yellowRedGizIcon = new Icon({
    iconUrl: 'markers/marker-icon-gold-red-giz.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  let greenIcon = new Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  let yellowIcon = new Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  let blueIcon = new Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  let redIcon = new Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  let chartOptions = {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: ['Trained', 'Employed']
    }
  };

  let trainedTotal = 0;
  let trainedWomen = 0;
  let trainedMen = 0;
  let trainedYouth = 0;

  let employedTotal = 0;
  let employedWomen = 0;
  let employedMen = 0;
  let employedYouth = 0;

  if (statistics && statistics.standardProgressDetails) {
    statistics.standardProgressDetails.filter(i => i.indicatorShortName === 'KT3.1').map((indicator) => {
      trainedTotal = indicator.currentValue;
      trainedWomen = indicator.secondaryCurrentValue;
      trainedMen = indicator.tertiaryCurrentValue;
      trainedYouth = indicator.quaternaryCurrentValue;
    });

    statistics.standardProgressDetails.filter(i => i.indicatorShortName === 'KT3.4').map((indicator) => {
      employedTotal = indicator.currentValue;
      employedWomen = indicator.secondaryCurrentValue;
      employedMen = employedTotal - employedWomen;
      employedYouth = indicator.tertiaryCurrentValue;
    });
  }

  let chartSeries = [
    {
      name: 'Total',
      data: [trainedTotal, employedTotal]
    }, {
      name: 'Women',
      data: [trainedWomen, employedWomen]
    }, {
      name: 'Men',
      data: [trainedMen, employedMen]
    }, {
      name: 'Youth (14-25)',
      data: [trainedYouth, employedYouth]
    }
  ];

  const getMarkerForLocation = (location) => {
    if (location.hasTvet) {
      if (location.hasHealth) {
        if (location.hasAppliedSciences) {
          if (location.hasGizOffice) {
            return blueYellowRedGizIcon;
          }
          return blueYellowRedIcon;
        }

        if (location.hasGizOffice) {
          return yellowRedGizIcon;
        }
        return yellowRedIcon;
      }
      if (location.hasAgro) {
        if (location.hasGizOffice) {
          return greenYellowGizIcon;
        }
        return greenYellowIcon;
      }

      if (location.hasGizOffice) {
        return yellowGizIcon;
      }
      return yellowIcon;
    }

    if (location.hasHealth) {
      if (location.hasGizOffice) {
        return redGizIcon;
      }
      return redIcon;
    }

    if (location.hasAppliedSciences) {
      if (location.hasGizOffice) {
        return blueGizIcon;
      }
      return blueIcon;
    }

    if (location.hasGizOffice) {
      return greenGizIcon;
    }
    return greenIcon;
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Access denied!
            </div>
          </>
      );
    }

    if (isLoading || !statistics) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
              Country map
            </Typography>
            <MapContainer
                whenCreated={setMapref}
                center={position}
                zoom={6}
                scrollWheelZoom={false}
                style={{height: 600, paddingTop: '2rem'}}
            >
              <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              { statistics.locationStatistics && statistics.locationStatistics.map((location) => (
                  <>
                    { location.lat && location.lng &&
                    <Marker
                        position={[location.lat, location.lng]} icon={getMarkerForLocation(location)} key={location.id}>
                      <Popup>
                        <div style={{fontSize: 14, fontWeight: 'bold'}}>{location.englishName}</div>
                        {location.institutions && location.institutions.length > 0 &&
                        <strong>Institutions:</strong>
                        }
                        <ul>
                          {location.institutions && location.institutions.map((i) => (
                              <li>{`${i.name}`}</li>
                          ))}
                        </ul>
                      </Popup>
                    </Marker>
                    }
                  </>
              ))}
            </MapContainer>
            <Typography variant="caption" color="textPrimary">
              Legend
            </Typography>
            <Box sx={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateColumns: 'repeat(10, 1fr)',
              gridTemplateRows: 'repeat(1, 50px)',
              gap: 1 }}>
              <Box>
                <img src='https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png' alt="Red Marker"/>
              </Box>
              <Box>
                Health
              </Box>
              <Box>
                <img src='https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png' alt="Yellow Marker"/>
              </Box>
              <Box>
                TVET
              </Box>
              <Box>
                <img src='https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png' alt="Blue Marker"/>
              </Box>
              <Box>
                UAS
              </Box>
              <Box>
                <img src='https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png' alt="Green Marker"/>
              </Box>
              <Box>
                Agro-Processing
              </Box>
              <Box>
                <img src='markers/marker-icon-blue-gold-red-giz.png' alt="GIZ Office Marker"/>
              </Box>
              <Box>
                GIZ Office
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{height: '100%'}} variant="outlined"
                 className={classes.paper}>
            <Typography variant="h5" color="textPrimary"
                        style={{paddingBottom: '1rem'}}>
              Standard indicator
            </Typography>
            <Typography variant="subtitle1" color="textPrimary"
                        style={{paddingBottom: '1rem'}}>
              No. of people trained / Jobs created or secured (in FTEs)
            </Typography>
            { statistics.standardProgressDetails && statistics.standardProgressDetails.filter(i => i.indicatorShortName === 'KT3.4' || i.indicatorShortName === 'KT3.1').length === 2 &&
                <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="bar"
                    height="550"
                />
            }
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
              Outcome Indicators
            </Typography>
            { statistics.outcomeProgressDetails && statistics.outcomeProgressDetails.map((outcome) => (
                <div className={classes.contentWrapper} key={outcome.indicatorShortName}>
                  <Typography variant="body1" color="textPrimary">{outcome.indicatorName}</Typography>
                  <Typography variant="body2">{truncate(outcome.description, 140)}</Typography>
                  <StyledLinearProgress
                      value={outcome.progress * 100}
                      current={outcome.currentValue}
                      target={outcome.targetValue}/>
                  { outcome.indicatorLevels && outcome.indicatorLevels > 1 &&
                      <StyledLinearProgress
                          value={outcome.secondaryProgress * 100}
                          current={outcome.secondaryCurrentValue}
                          target={outcome.secondaryTargetValue}/>
                  }
                  { outcome.indicatorLevels && outcome.indicatorLevels > 2 &&
                      <StyledLinearProgress
                          value={outcome.tertiaryProgress * 100}
                          current={outcome.tertiaryCurrentValue}
                          target={outcome.tertiaryTargetValue}/>
                  }
                </div>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
              Output Indicators
            </Typography>
            { statistics.outputProgressDetails && statistics.outputProgressDetails.map((output) => (
                <div className={classes.contentWrapper} key={output.indicatorShortName}>
                  <Typography variant="body1" color="textPrimary">{output.indicatorName}</Typography>
                  <Typography variant="body2">{truncate(output.description, 140)}</Typography>
                  <StyledLinearProgress
                      value={output.progress * 100}
                      current={output.currentValue}
                      target={output.targetValue}/>
                  { output.indicatorLevels && output.indicatorLevels > 1 &&
                      <StyledLinearProgress
                          value={output.secondaryProgress * 100}
                          current={output.secondaryCurrentValue}
                          target={output.secondaryTargetValue}/>
                  }
                  { output.indicatorLevels && output.indicatorLevels > 2 &&
                      <StyledLinearProgress
                          value={output.tertiaryProgress * 100}
                          current={output.tertiaryCurrentValue}
                          target={output.tertiaryTargetValue}/>
                  }
                </div>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary">
              Ongoing activities
            </Typography>
            <Typography color="textPrimary" style={{paddingBottom: '1rem', fontSize: 12}}>
              *Delayed activities marked in red
            </Typography>
            {statistics.currentActivities.length > 0 &&
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell width={'40%'}>Name</TableCell>
                        <TableCell width={'20%'}>Type</TableCell>
                        <TableCell width={'20%'}>Start date</TableCell>
                        <TableCell width={'20%'}>End date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {statistics.currentActivities
                          && statistics.currentActivities.map(
                              (activity) => (
                                  <TableRow key={activity.id} style={{backgroundColor: activity.plannedEndDate && new Date(activity.plannedEndDate) < new Date() && !activity.completed && !activity.endDate && '#fb6767'}}>
                                    <TableCell component="th"
                                               scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                                    <TableCell>{formatActivityType(
                                        activity.activityType)}</TableCell>
                                    <TableCell>{activity.date ? formatTime(
                                        activity.date) : formatTime(
                                      activity.plannedDate)}</TableCell>
                                    <TableCell>{activity.endDate ? formatTime(
                                        activity.endDate) : formatTime(
                                        activity.plannedEndDate)}</TableCell>
                                  </TableRow>
                              ))}
                    </TableBody>
                  </Table>
                </TableContainer>
            }
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{height: '100%'}} variant="outlined"
                 className={classes.paper}>
            <Typography variant="h5" color="textPrimary"
                        style={{paddingBottom: '1rem'}}>
              Completed activities
            </Typography>
            {statistics.pastActivities.length > 0 &&
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell width={'40%'}>Name</TableCell>
                    <TableCell width={'20%'}>Type</TableCell>
                    <TableCell width={'20%'}>Start date</TableCell>
                    <TableCell width={'20%'}>End date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statistics.pastActivities
                  && statistics.pastActivities.map(
                      (activity) => (
                          <TableRow key={activity.id}>
                            <TableCell component="th"
                                       scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                            <TableCell>{formatActivityType(
                                activity.activityType)}</TableCell>
                            <TableCell>{activity.date ? formatTime(
                                activity.date) : formatTime(
                                activity.plannedDate)}</TableCell>
                            <TableCell>{activity.endDate ? formatTime(
                                activity.endDate) : formatTime(
                                activity.plannedEndDate)}</TableCell>
                          </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  };

  return renderData();
}

export default withStyles(styles)(Dashboard);