import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  createLocation, getLocationDump, getLocations, updateLocation
} from "../../utils/api";
import {CircularProgress, Fab} from "@mui/material";
import {withStyles} from "@mui/styles";
import {DataGrid} from "@mui/x-data-grid";
import {UserContext} from "../auth/UserProvider";
import AddLocationDialog from "../dialogs/AddLocationDialog";
import DownloadIcon from "@mui/icons-material/GetApp";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import * as XLSX from "xlsx";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '3rem',
    right: '2rem',
  },
});


function LocationSettings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [userList, setUserList] = useState('');
  const [locationBuffer, setLocationBuffer] = useState({});
  const [isLoadingDump, setIsLoadingDump] = useState(false);

  const authState = useContext(UserContext);

  const addConfirmationHandler =
      async (name, lat, lng, isHealth, isTvet, isAppliedSciences, isAgro, isGizOffice) => {
        await createLocation({
          'englishName': name,
          'lat': +lat,
          'lng': +lng,
          'hasHealth': isHealth,
          'hasTvet': isTvet,
          'hasAppliedSciences': isAppliedSciences,
          'hasAgro': isAgro,
          'hasGizOffice': isGizOffice,
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, name, lat, lng, isHealth, isTvet, isAppliedSciences, isAgro, isGizOffice) => {
        await updateLocationAndDispatch(entityId, {
          'englishName': name,
          'lat': +lat,
          'lng': +lng,
          'hasHealth': isHealth,
          'hasTvet': isTvet,
          'hasAppliedSciences': isAppliedSciences,
          'hasAgro': isAgro,
          'hasGizOffice': isGizOffice,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const fetchData =
      async (loading) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getLocations();
          const rolesData = result.data;

          if (!cancelRequest.current) {
            if (rolesData && Object.keys(rolesData).length > 0) {
              setUserList(rolesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, []);

  const downloadLocationDump = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Location list');

    let ws_data = [
      ['Id', 'Name', 'Latitude', 'Longitude'],
    ];

    data.map(l => {
      ws_data.push([l.id, l.englishName, l.lat, l.lng]);
    });

    wb.Sheets['Location list'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,'location_list.xlsx');
  };

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getLocationDump()
              .then((response) => {
                setIsLoadingDump(false);
                if (response.data) {
                  downloadLocationDump(response.data);
                }
              });
        } catch (error) {
          console.log(error);
        }
      };

  const updateLocationAndDispatch = async (locationId, fields) => {
    try {
      let buffer = {
        ...locationBuffer
      };

      setLocationBuffer(buffer);

      const { data: data } = await updateLocation(
          locationId,
          fields
      );

      if (!cancelRequest.current) {
        setLocationBuffer(data);
      }
    } catch (error) {
      setLocationBuffer(locationBuffer);
    }
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Access denied!
            </div>
          </>
      );
    }

    if (isLoading || !userList) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <DataGrid
              hideFooter={true}
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={userList}
              columns={[
                { field: 'englishName', headerName: 'Name', flex: 1 },
                { field: 'lat', headerName: 'Latitude', flex: 1 },
                { field: 'lng', headerName: 'Longitude', flex: 1 },
                { field: 'hasHealth', headerName: 'Health', flex: 1,
                  renderCell: (params) =>
                      params.row.hasHealth ? 'Yes' : 'No'
                },
                { field: 'hasTvet', headerName: 'TVET', flex: 1,
                  renderCell: (params) =>
                      params.row.hasTvet ? 'Yes' : 'No'
                },
                { field: 'hasAppliedSciences', headerName: 'UAS', flex: 1,
                  renderCell: (params) =>
                      params.row.hasAppliedSciences ? 'Yes' : 'No'
                },
                { field: 'hasAgro', headerName: 'Agro', flex: 1,
                  renderCell: (params) =>
                      params.row.hasAgro ? 'Yes' : 'No'
                },
                { field: 'hasGizOffice', headerName: 'GIZ Office', flex: 1,
                  renderCell: (params) =>
                      params.row.hasGizOffice ? 'Yes' : 'No'
                },
                {
                  field: 'actions',
                  headerName: 'Action',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <AddLocationDialog
                        confirmationHandler={updateConfirmationHandler}
                        action="edit"
                        entity={params.row} />;
                  }
                }
              ]}
              density="compact"
              rowCount={userList.length}
          />
          {authState.role === 'ROLE_SUPER' &&
            <>
              <div>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => fetchDump()}
                    className={classes.fab}
                    style={{position: 'fixed', marginBottom: '0.075rem', marginRight: '14rem' }}
                >
                  <DownloadIcon/>
                </Fab>
              </div>
              <AddLocationDialog
                  confirmationHandler={addConfirmationHandler}/>
            </>
          }
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(LocationSettings);