import axios from 'axios';
import { auth } from "../components/auth/firebase";

let token = null;

async function setupAuthorizationHeaders() {
  token = await auth.currentUser.getIdToken();

  return {
    Authorization: `Bearer ${token}`,
  };
}

const api = axios.create({
  baseURL: process.env.REACT_APP_STEP_ETHIOPIA_BACKEND,
  headers: {
    accept: 'application/json',
  },
});

api.interceptors.request.use(
    async config => {
      const newConfig = config;
      const newHeaders = await setupAuthorizationHeaders();
      newConfig.headers = {
        ...config.headers,
        ...newHeaders,
      };
      return newConfig;
    },
    error => {
      Promise.reject(error);
    }
);

export default api;

/* --- GET requests --- */

export async function getActivities(state, searchTerm = '', sector = '', gender = '', greenTvet = '', activityType = '', page = 0, size = 10) {
  return api.get(`/activities?state=${state}&searchTerm=${searchTerm}&sector=${sector}&page=${page}&size=${size}&activityType=${activityType}&gender=${gender}&greenTvet=${greenTvet}`);
}

export async function getActivity(activityId) {
  return api.get(`/activities/${activityId}`);
}

export async function getIndicators() {
  return api.get('/indicators');
}

export async function getStatistics() {
  return api.get('/statistics');
}

export function getProgrammeAnalysis() {
  return api.get('/analysis/programme');
}

export function getModuleAnalysis() {
  return api.get('/analysis/module');
}

export function getOutputAnalysis(outputId) {
  return api.get(`/analysis/output/${outputId}`);
}

export async function getUsers() {
  return api.get(`/users`);
}

export async function getLocations() {
  return api.get(`/locations`);
}

export async function getPartners() {
  return api.get(`/partners`);
}

export async function getInstitutions() {
  return api.get(`/institutions`);
}

export async function searchActivities(searchTerm) {
  return api.get(`/activities-search?searchTerm=${searchTerm}`);
}

export async function searchSectors(searchTerm) {
  return api.get(`/sectors-search?searchTerm=${searchTerm}`);
}

export async function searchLocations(searchTerm) {
  return api.get(`/locations-search?searchTerm=${searchTerm}`);
}

export async function searchPartners(searchTerm) {
  return api.get(`/partners-search?searchTerm=${searchTerm}`);
}

export async function searchInstitutions(searchTerm) {
  return api.get(`/institutions-search?searchTerm=${searchTerm}`);
}

export async function getActivityDump(sector = null) {
  return api.get(`/activities-dump?sector=${sector ? sector : ''}`);
}

export async function getInstitutionDump() {
  return api.get(`/institutions-dump`);
}

export async function getLocationDump() {
  return api.get(`/locations-dump`);
}

export async function getPartnerDump() {
  return api.get(`/partners-dump`);
}

export async function download(uuid) {
  return api.get(`/download?uuid=${uuid}`, {
    responseType: 'blob',
    timeout: 30000,
  });
}

/* --- POST requests ---*/

export async function createActivity(body) {
  return api.post(`/activities`, body);
}

export async function createIndicator(body) {
  return api.post(`/indicators`, body);
}

export async function addIndicatorValue(indicatorId, body) {
  return api.post(`/indicators/${indicatorId}/value`, body, {
    responseType: 'application/json',
    headers: {
      accept: 'multipart/form-data'
    }
  });
}

export async function addTargetValue(activityId, body) {
  return api.post(`/activities/${activityId}/targetvalue`, body);
}

export async function addSurveyEntry(activityId, body) {
  return api.post(`/activities/${activityId}/survey/entry`, body);
}

export async function addFollowUpSurveyEntry(activityId, body) {
  return api.post(`/activities/${activityId}/followupsurvey/entry`, body);
}

export async function createUser(body) {
  return api.post(`/users`, body);
}

export async function createLocation(body) {
  return api.post(`/locations`, body);
}

export async function createPartner(body) {
  return api.post(`/partners`, body);
}

export async function createInstitution(body) {
  return api.post(`/institutions`, body);
}

/* --- PUT requests ---*/

export async function updateActivity(activityId, body) {
  return api.put(`/activities/${activityId}`, body);
}

export async function updateIndicator(indicatorId, body) {
  return api.put(`/indicators/${indicatorId}`, body);
}

export async function updateIndicatorValue(indicatorId, indicatorValueId, body) {
  return api.put(`/indicators/${indicatorId}/value/${indicatorValueId}`, body);
}

export async function updateLocation(locationId, body) {
  return api.put(`/locations/${locationId}`, body);
}

export async function updatePartner(partnerId, body) {
  return api.put(`/partners/${partnerId}`, body);
}

export async function updateInstitution(institutionId, body) {
  return api.put(`/institutions/${institutionId}`, body);
}

/* --- DELETE requests ---*/

export async function deleteActivity(activityId, deleteType = 'SOFT') {
  return api.delete(`/activities/${activityId}?deleteType=${deleteType}`);
}

export async function deleteIndicator(indicatorId) {
  return api.delete(`/indicators/${indicatorId}`);
}

export async function deleteEntryFromSurvey(activityId, entryId) {
  return api.delete(`/activities/${activityId}/survey/entry/${entryId}`);
}

export async function deleteEntryFromFollowUpSurvey(activityId, entryId) {
  return api.delete(`/activities/${activityId}/followupsurvey/entry/${entryId}`);
}

export async function deleteTargetValue(activityId, targetValueId) {
  return api.delete(`/activities/${activityId}/targetvalue/${targetValueId}`);
}

export async function deleteIndicatorValue(indicatorId, indicatorValueId) {
  return api.delete(`/indicators/${indicatorId}/value/${indicatorValueId}`);
}

export async function deleteUser(usersId) {
  return api.delete(`/users/${usersId}`);
}