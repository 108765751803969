import React, {useState} from 'react';
import {
  Button, Checkbox,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormControlLabel, FormGroup,
  IconButton, InputLabel, MenuItem, Select,
  Tooltip
} from "@mui/material";
import {withStyles} from "@mui/styles";
import {FilterList, FilterListOff} from "@mui/icons-material";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function SelectActivityFilterDialog(props) {
  const { classes, confirmationHandler, clearFilterHandler } = props;
  const [open, setOpen] = React.useState(false);
  const [ filterActivated, setFilterActivated ] = useState(false);
  const [ activityType, setActivityType ] = useState('ALL');
  const [ gender, setGender ] = useState(false);
  const [ greenTvt, setGreenTvt ] = useState(false);

  const handleClickOpen = () => {
    if (filterActivated) {
      clearFilterHandler();
      setFilterActivated(false);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setActivityType('ALL');
    setGender(false);
    setGreenTvt(false);
  };

  const handleConfirm = () => {
    confirmationHandler(gender ? 'true' : '', greenTvt ? 'true' : '', activityType === 'ALL' ? '' : activityType);
    setFilterActivated(true);
    handleClose();
  };

  return (
      <>
        <Tooltip title={filterActivated ? 'Discard filters' : 'Apply filters'}>
          <IconButton  onClick={handleClickOpen}>
            { filterActivated ? <FilterListOff sx={{ fontSize: 24}} /> : <FilterList sx={{ fontSize: 24}} /> }
          </IconButton>
        </Tooltip>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Filter activity list</DialogTitle>
          <DialogContent>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Type
              </InputLabel>
              <Select
                  labelId="activity-type-select"
                  id="activity-type-select"
                  value={activityType}
                  onChange={(event) => setActivityType(event.target.value)}
                  className={classes.selectEmpty}
              >
                <MenuItem value={'ALL'}>All activities</MenuItem>
                <MenuItem value={'COOPERATIVE_TRAINING'}>Cooperative Training</MenuItem>
                <MenuItem value={'SHORT_TERM_TRAINING'}>Short-term Training</MenuItem>
                <MenuItem value={'FURTHER_TRAINING'}>Further Training</MenuItem>
                <MenuItem value={'LEADERSHIP_TRAINING'}>Leadership Training</MenuItem>
                <MenuItem value={'PUBLIC_PRIVATE_DIALOGUE'}>Public Private Dialogue</MenuItem>
                <MenuItem value={'POLICY_SUPPORT'}>Policy Support</MenuItem>
                <MenuItem value={'COMPANY_SUPPORt'}>Company Support</MenuItem>
                <MenuItem value={'DIGITALIZATION'}>Digitalization</MenuItem>
                <MenuItem value={'OTHER'}>Other</MenuItem>
              </Select>
            </FormControl>
            <FormGroup>
              <FormControlLabel
                  sx={{ alignItems: 'flex-start', marginTop: 2 }}
                  control={
                    <Checkbox
                        checked={gender}
                        color="primary"
                        sx={{
                          marginTop: -1.6,
                        }}
                        onChange={(e) => {
                          let checked = e.target.checked;
                          setGender(checked);
                        }
                        } />}
                  label="Gender" />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                  sx={{ alignItems: 'flex-start', marginTop: 2 }}
                  control={
                    <Checkbox
                        checked={greenTvt}
                        color="primary"
                        sx={{
                          marginTop: -1.6,
                        }}
                        onChange={(e) => {
                          let checked = e.target.checked;
                          setGreenTvt(checked);
                        }
                        } />}
                  label="Green TVT" />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" size="small">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" size="small">
              Apply filter
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(SelectActivityFilterDialog)