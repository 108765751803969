import React, {useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {searchActivities} from "../../utils/api";
import {
  Autocomplete,
  Avatar,
  Checkbox, Chip,
  FormControlLabel,
  FormGroup,
  Tooltip
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AttachmentIcon from "@mui/icons-material/Attachment";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {FileDrop} from "react-file-drop";

export default function AddIndicatorValueDialog(props) {
  const { indicator, entity, confirmationHandler, action = 'create', disableActivityUpdate = false } = props;
  const [open, setOpen] = useState(false);
  const [indicatorValueDescription, setIndicatorValueDescription] = useState('');
  const [indicatorValueActivity, setIndicatorValueActivity] = useState(undefined);
  const [indicatorValueComment, setIndicatorValueComment] = useState('');
  const [output, setOutput] = useState(undefined);
  const [numericValue, setNumericValue] = useState(0);
  const [secondaryNumericValue, setSecondaryNumericValue] = useState(0);
  const [tertiaryNumericValue, setTertiaryNumericValue] = useState(0);
  const [quaternaryNumericValue, setQuaternaryNumericValue] = useState(0);
  const [attachments, setAttachments] = useState([]);

  const fileInputRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
    setIndicatorValueDescription(entity && entity.description ? entity.description : '');
    setIndicatorValueActivity(entity && entity.activity ? entity.activity : undefined);
    setIndicatorValueComment(entity && entity.comment ? entity.comment : '');
    setNumericValue(entity && entity.numericValue ? entity.numericValue : 0);
    setSecondaryNumericValue(entity && entity.secondaryNumericValue ? entity.secondaryNumericValue : 0);
    setTertiaryNumericValue(entity && entity.tertiaryNumericValue ? entity.tertiaryNumericValue : 0);
    setQuaternaryNumericValue(entity && entity.quaternaryNumericValue ? entity.quaternaryNumericValue : 0);
    setOutput(entity && entity.output ? entity.output : undefined);
    setAttachments([]);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    action === 'create' ?
      confirmationHandler(
          indicator.id,
          indicatorValueDescription,
          indicatorValueActivity,
          output,
          indicatorValueComment,
          numericValue,
          indicator && indicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
          indicator && indicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null,
          indicator && indicator.quaternaryTargetValueNumeric ? quaternaryNumericValue : null,
          attachments) :
        confirmationHandler(
            entity.id,
            indicator.id,
            indicatorValueDescription,
            indicatorValueActivity,
            output,
            indicatorValueComment,
            numericValue,
            indicator && indicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
            indicator && indicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null,
            indicator && indicator.quaternaryTargetValueNumeric ? quaternaryNumericValue : null,
            attachments);
    handleClose();
  };

  const clearFormData = () => {
    setIndicatorValueDescription('');
    setIndicatorValueActivity(undefined);
    setIndicatorValueComment('');
    setNumericValue(0);
    setSecondaryNumericValue(0);
    setTertiaryNumericValue(0);
    setQuaternaryNumericValue(0);
    setAttachments([]);
    setOutput(undefined);
  };

  const onIndicatorValueDescriptionChange = (e) => {
    setIndicatorValueDescription(e.target.value);
  };

  const onIndicatorValueCommentChange = (e) => {
    setIndicatorValueComment(e.target.value);
  };

  const onNumericValueChange = (e) => {
    setNumericValue(e.target.value);
  };

  const onSecondaryNumericValueChange = (e) => {
    setSecondaryNumericValue(e.target.value);
  };

  const onTertiaryNumericValueChange = (e) => {
    setTertiaryNumericValue(e.target.value);
  };

  const onQuatenaryNumericValueChange = (e) => {
    setQuaternaryNumericValue(e.target.value);
  };

  const changeInput = value => {
    setIndicatorValueActivity(value);
  };

  const changeOutput = event => {
    setOutput(event.target.value);
  };

  const onTargetClick = () => {
    fileInputRef.current.click()
  }

  const onFileInputChange = (event) => {
    const { files } = event.target;
    setAttachments([
      ...attachments,
      ...files
    ]);
  }

  const outputs = [
    { name: 'Output 1', value: 'OUTPUT_1' },
    { name: 'Output 2', value: 'OUTPUT_2' },
    { name: 'Output 3', value: 'OUTPUT_3' },
    { name: 'Output 4', value: 'OUTPUT_4' },
    { name: 'Output 5', value: 'OUTPUT_5' },
  ];

  return (
      <div>
        {action === 'create' &&
          <Button variant="outlined" color="primary" sx={{fontSize: 14}} onClick={handleClickOpen}>
            Add { indicator && indicator.indicatorType !== 'STANDARD' && indicator.targetValueNumeric === 1 ? 'milestone' : 'value'}
          </Button>
        }
        { action === 'edit' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Tooltip>
        }
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} { indicator && indicator.indicatorType !== 'STANDARD' && indicator.targetValueNumeric === 1 ? 'milestone' : 'value'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please add the necessary information
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Description"
                type="text"
                value={indicatorValueDescription}
                onChange={onIndicatorValueDescriptionChange}
                fullWidth
                variant="outlined"
            />
            { !disableActivityUpdate && indicator.indicatorType !== 'OUTCOME' &&
              <BasicAutocomplete handleChange={changeInput} searchFct={searchActivities} currentValue={indicatorValueActivity} label={'Activity'} property={'name'}/>
            }
            { indicator.indicatorType === 'OUTCOME' &&
                <Autocomplete
                    id="output-outlined"
                    options={outputs}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    value={output}
                    onChange={(value) => changeOutput(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Output"
                        />
                    )}
                />
            }
            <TextField
                autoFocus={false}
                margin="normal"
                id="name"
                label="Comment"
                type="text"
                value={indicatorValueComment}
                onChange={onIndicatorValueCommentChange}
                fullWidth
                variant="outlined"
            />
            { indicator && indicator.indicatorType !== 'STANDARD' && indicator.targetValueNumeric === 1 &&
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={numericValue !== 0} onChange={(event) => setNumericValue(event.target.checked ? 1 : 0)} />} label="Mark indicator as complete" />
              </FormGroup>
            }
            { indicator && (indicator.targetValueNumeric !== 1 || (indicator.targetValueNumeric === 1 && indicator.indicatorType === 'STANDARD')) &&
              <TextField
                  autoFocus={false}
                  margin="normal"
                  id="name"
                  label={`Value ${indicator.targetValueDescription ? '(' + indicator.targetValueDescription + ')' : ''}`}
                  value={numericValue}
                  onChange={onNumericValueChange}
                  type="number"
                  variant="outlined"
              />
            }
            { indicator && indicator.secondaryTargetValueNumeric &&
                <>
                  <br/>
                  <TextField
                      autoFocus={false}
                      margin="normal"
                      id="name"
                      label={`Secondary value (${indicator.secondaryTargetValueDescription})`}
                      value={secondaryNumericValue}
                      onChange={onSecondaryNumericValueChange}
                      type="number"
                      variant="outlined"
                  />
                </>
            }
            { indicator &&  indicator.tertiaryTargetValueNumeric &&
              <>
                <br/>
                <TextField
                    autoFocus={false}
                    margin="normal"
                    id="name"
                    label={`Tertiary value (${indicator.tertiaryTargetValueDescription})`}
                    value={tertiaryNumericValue}
                    onChange={onTertiaryNumericValueChange}
                    type="number"
                    variant="outlined"
                />
              </>
            }
            { indicator &&  indicator.quaternaryTargetValueNumeric &&
                <>
                  <br/>
                  <TextField
                      autoFocus={false}
                      margin="normal"
                      id="name"
                      label={`Quaternary value (${indicator.quaternaryTargetValueDescription})`}
                      value={quaternaryNumericValue}
                      onChange={onQuatenaryNumericValueChange}
                      type="number"
                      variant="outlined"
                  />
                </>
            }
            <br/>
            <input
                onChange={onFileInputChange}
                ref={fileInputRef}
                type="file"
                multiple="multiple"
                style={{display:'none'}}
            />
            { attachments && attachments.length > 0 && attachments.map(file => (
                <Chip sx={{margin: 1}} avatar={<Avatar>{file.name.slice(file.name.lastIndexOf('.') + 1)}</Avatar>} label={file.name} />
            ))}
            <div style={{ backgroundColor: '#EEEEEE', borderRadius: '5px', border: '1px solid #EEEEEE', width: 550, color: '#666666', padding: 20 }}>
              <FileDrop
                  onDrop={(files) => {
                    setAttachments([...attachments, ...files]);
                  }}
                  onTargetClick={onTargetClick}
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  margin: '16px 16px',
                }}>
                  <AttachmentIcon sx={{ fontSize: 24, marginRight: 1 }} />
                  <span>Drop one or multiple files here for documentation!</span>
                </div>

              </FileDrop>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" size="small">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" size="small">
              { action === 'create' ? 'Add' : 'Edit'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}