import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBKF4mJ50pXdl5ix0DXzGFn0xz-guOoJGc",
  authDomain: "giz-step-ethiopia.firebaseapp.com",
  projectId: "giz-step-ethiopia",
  storageBucket: "giz-step-ethiopia.appspot.com",
  messagingSenderId: "374954298101",
  appId: "1:374954298101:web:b5aba884688595fbcfd3bc"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();