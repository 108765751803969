import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import EditableInput from "../input/EditableInput";
import {
  createActivity, deleteActivity,
  getActivities, getActivityDump, searchSectors,
  updateActivity
} from "../../utils/api";
import {formatTime} from '../../utils/timeHelper';
import {
  formatActivityType
} from "../../utils/activityTypeHelper";
import {truncate} from "../../utils/stringHelper";
import debounce from 'lodash.debounce';
import {useInView} from "react-cool-inview";
import {makeStyles, withStyles} from "@mui/styles";
import {
  Tooltip,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  ImageList,
  AppBar,
  Chip,
  Collapse,
  MenuItem,
  TableBody,
  IconButton,
  Select,
  Paper,
  Toolbar,
  TableContainer,
  TextField,
  CircularProgress,
  Fab,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment
} from "@mui/material";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import AddActivityDialog from "../dialogs/AddActivityDialog";
import {DescriptionList, DescriptionTerm, Description} from "../parts/Description.parts";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import DownloadIcon from "@mui/icons-material/GetApp";
import {UserContext} from "../auth/UserProvider";
import * as XLSX from "xlsx";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import IncompleteIcon from "@mui/icons-material/Cancel";
import CompleteIcon from "@mui/icons-material/Verified";
import SelectActivityFilterDialog from "../dialogs/SelectActivityFilterDialog";

const styles = () => ({
  contentWrapper: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
  },
  tableContainer: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
    overflow: 'hidden',
  },
  tableHead: {
    background: '#F5F5F5',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function Row(props) {
  let { activity, fetchData, shouldOpen, removeHandler, state, obsrv,isReadOnlyUser = false } = props;
  const [open, setOpen] = useState(shouldOpen);
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [stateActivity, setStateActivity] = useState(activity);
  const [activityBuffer, setActivityBuffer] = useState(activity);

  const handleCompletionStatusChange = (status) => {
    updateActivityDataAndDispatch('completed', status);
  };

  const handleIsGenderChange = (status) => {
    updateActivityDataAndDispatch('isGender', status);
  };

  const handleIsGreenTvtChange = (status) => {
    updateActivityDataAndDispatch('isGreenTvt', status);
  };

  const updateActivityDataAndDispatch = async (field, value) => {
    try {
      setActivityBuffer({
        ...activityBuffer,
        [field]: value,
      });
      const { data: activityData } = await updateActivity(
          activity.id,
          { [field]: value }
      );

      if (!cancelRequest.current) {
        setStateActivity(activityData);
      }
    } catch (error) {
      setActivityBuffer(activityBuffer);
    }
  };

  const handleActivityTypeChange = async (event) => {
    await updateActivityDataAndDispatch('activityType', event.target.value);
    await fetchData(true);
  };

  return (
      <React.Fragment>
        <TableRow ref={obsrv} className={classes.root}>
          <TableCell style={{ paddingBottom: 0, border: "none" }} colSpan={6}>
            <strong>{stateActivity.name}</strong>
          </TableCell>
        </TableRow>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{stateActivity.activityType !== 'OTHER' ? formatActivityType(stateActivity.activityType) : stateActivity.otherTypeName }</TableCell>
          <TableCell>{formatTime(stateActivity.date, 'dd.LL.yyyy')}</TableCell>
          <TableCell>
            {stateActivity.indicators && stateActivity.indicators.map((indicator) => (
                <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{indicator.name}</Typography>
                        {indicator.description}
                      </React.Fragment>
                    }
                >
                  <Chip size="small" style={{marginBottom: '0.2rem', marginRight: '0.8rem'}} label={truncate(indicator.name, 30)} />
                </HtmlTooltip>
            ))}
          </TableCell>
          <TableCell>{stateActivity.completed ? <CompleteIcon sx={{margin: '16px 16px', color: 'green'}} /> : <IncompleteIcon  sx={{margin: '16px 16px', color: 'orange'}} />}</TableCell>
          <TableCell width={'15%'}>
            { state !== 'DELETED' && !isReadOnlyUser &&
              <ConfirmActionDialog action={'delete'} confirmationHandler={() => removeHandler(stateActivity.id)}/>
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <Typography variant="h5" gutterBottom component="div" style={{marginTop: '1rem'}}>
                Activity details
              </Typography>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      1. General information
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Type</DescriptionTerm>
                      <Description>
                        <Select
                            labelId="activity-type-select"
                            id="activity-type-select"
                            value={stateActivity.activityType}
                            className={classes.selectEmpty}
                            onChange={handleActivityTypeChange}
                        >
                          <MenuItem value={'COOPERATIVE_TRAINING'}>Cooperative Training</MenuItem>
                          <MenuItem value={'SHORT_TERM_TRAINING'}>Short-term Training</MenuItem>
                          <MenuItem value={'FURTHER_TRAINING'}>Further Training</MenuItem>
                          <MenuItem value={'LEADERSHIP_TRAINING'}>Leadership Training</MenuItem>
                          <MenuItem value={'PUBLIC_PRIVATE_DIALOGUE'}>Public Private Dialogue</MenuItem>
                          <MenuItem value={'POLICY_SUPPORT'}>Policy Support</MenuItem>
                          <MenuItem value={'COMPANY_SUPPORT'}>Company Support</MenuItem>
                          <MenuItem value={'DIGITALIZATION'}>Digitalization</MenuItem>
                          <MenuItem value={'OTHER'}>Other</MenuItem>
                        </Select>
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <FormGroup>
                        <FormControlLabel
                            sx={{ alignItems: 'flex-start', marginTop: 2 }}
                            control={
                              <Checkbox
                                  checked={stateActivity.isGender}
                                  color="primary"
                                  sx={{
                                    marginTop: -1.6,
                                  }}
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    handleIsGenderChange(checked);
                                  }
                                  } />}
                            label="Gender" />
                      </FormGroup>
                    </DescriptionList>
                    <br/>
                    <DescriptionList>
                      <FormGroup>
                        <FormControlLabel
                            sx={{ alignItems: 'flex-start', marginTop: 2 }}
                            control={
                              <Checkbox
                                  checked={stateActivity.isGreenTvt}
                                  color="primary"
                                  sx={{
                                    marginTop: -1.6,
                                  }}
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    handleIsGreenTvtChange(checked);
                                  }
                                  } />}
                            label="Green TVT" />
                      </FormGroup>
                    </DescriptionList>
                    <br/>
                    <DescriptionList>
                      <DescriptionTerm>Name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            alignWithBigRow
                            name="name"
                            value={stateActivity.name}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            multiline={true}
                            rows={4}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Planned start date</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="plannedDate"
                            type="date"
                            value={formatTime(stateActivity.plannedDate, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName,
                                     value) => updateActivityDataAndDispatch(fieldName,
                                value + ' 00:00:00.000')}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Planned end date</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="plannedEndDate"
                            type="date"
                            value={formatTime(stateActivity.plannedEndDate,
                                'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName,
                                     value) => updateActivityDataAndDispatch(fieldName,
                                value + ' 00:00:00.000')}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Start date</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="date"
                            type="date"
                            value={formatTime(stateActivity.date, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value + ' 00:00:00.000')}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>End date</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="endDate"
                            type="date"
                            value={formatTime(stateActivity.endDate, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value + ' 00:00:00.000')}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Product owner</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="moderator"
                            value={stateActivity.moderator}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Responsible team</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="organizer"
                            value={stateActivity.organizer}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Implementing party</DescriptionTerm>
                      <Description>
                        <EditableInput
                            alignWithBigRow
                            name="implementingParty"
                            value={stateActivity.implementingParty}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Sector</DescriptionTerm>
                      <Description>
                        <BasicAutocomplete multiple handleChange={(value) => updateActivityDataAndDispatch('sectors', value)} searchFct={searchSectors} currentValue={stateActivity.sectors} property={'sector'} />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Comments</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="comment"
                            value={stateActivity.comment}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <br/>
                    <DescriptionList>
                        <FormGroup>
                          <FormControlLabel
                              sx={{ alignItems: 'flex-start', marginTop: 2 }}
                              control={
                                <Checkbox
                                    checked={stateActivity.completed}
                                    color="primary"
                                    sx={{
                                      marginTop: -1.6,
                                    }}
                                    onChange={(e) => {
                                      let checked = e.target.checked;
                                      handleCompletionStatusChange(checked);
                                    }
                                    } />}
                              label="Activity complete" />
                        </FormGroup>
                    </DescriptionList>
                  </ImageList>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      2. Participant information
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={3}>
                    <DescriptionList>
                      <DescriptionTerm>Total number of participants</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="numberParticipants"
                            value={stateActivity.numberParticipants}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Number of women</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="numberFemaleParticipants"
                            value={stateActivity.numberFemaleParticipants}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  </div>
                </Paper>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
  );
}

function Activities(props) {
  const { classes, state, sector } = props;
  const [items, setItems] = useState([]);
  const pageToLoad = useRef(0);
  const initialPageLoaded = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const [expanded, setExpanded] = React.useState('panel1');
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const didMount = useRef(false);
  const [gender, setGender] = useState('');
  const [greenTvet, setGreenTvet] = useState('');
  const [activityType, setActivityType] = useState('');


  const authState = useContext(UserContext);

  const { observe } = useInView({
    // For better UX, we can grow the root margin so the data will be loaded earlier
    rootMargin: "50px 0px",
    // When the last item comes to the viewport
    onEnter: ({ unobserve, observe }) => {
      if (hasMore) {
        // Pause observe when loading data
        unobserve();
        // Load more data
        loadItems(false).then(observe());

        initialPageLoaded.current = true;
        pageToLoad.current = Number(pageToLoad.current) + 1;
      }

    },
  });

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const downloadActivityDump = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Activity list');

    let ws_data = [
      ['Id', 'Type', 'Name', 'Start date', 'End date', 'Comments', 'Number of participants',
      'Female participants', 'Product owner',
        'Responsible team'],
    ];

    data.map(a => {
      ws_data.push([a.id, formatActivityType(a.activityType), a.name, formatTime(a.date),
        formatTime(a.endDate), a.comment, a.numberParticipants,
        a.numberFemaleParticipants, a.moderator,
        a.organizer]);
    });

    wb.Sheets['Activity list'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,'activity_list.xlsx');
  };

  const fetchDump =
      async (sector) => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getActivityDump(sector)
          .then((response) => {
            setIsLoadingDump(false);
            if (response.data) {
              downloadActivityDump(response.data);
            }
          });
        } catch (error) {
          console.log(error);
        }
      };

  const loadItems = async (reset = false) => {
    console.log('Loading items');
    const data = await getActivities(state, searchTerm, sector, gender, greenTvet, activityType, reset ? 0 : pageToLoad.current);
    setHasMore(data.data.totalPages > pageToLoad.current);
    setItems(reset ? data.data.content : prevItems => [...prevItems, ...data.data.content].reduce((unique, o) => {
      if(!unique.some(obj => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    },[]));

    if (reset) {
      pageToLoad.current = 0;
    }
  };

  const removeActivity = async (activityId, deleteType = 'SOFT') => {
    await deleteActivity(activityId, deleteType);
    await loadItems(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSearchTermChanged = (event) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const addActivityConfirmationHandler =
      async (activityType) => {
        await createActivity({
          'activityType': activityType,
        });
        await loadItems(true);
      };

  const confirmApplyFilterHandler =
      async (gender, greenTvet, activityType) => {
        setGender(gender);
        setGreenTvet(greenTvet);
        setActivityType(activityType);
      };

  const confirmClearFilterHandler =
      async () => {
        setGender('');
        setGreenTvet('');
        setActivityType('');
      };

  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }

    loadItems(false);

    initialPageLoaded.current = true;
    pageToLoad.current = Number(pageToLoad.current) + 1;
  }, [loadItems]);

  useEffect(() => {
    if (didMount.current) {
      loadItems(true);
    }
    else didMount.current = true;
  }, [searchTerm]);

  useEffect(() => {
    if (didMount.current) {
      loadItems(true);
    }
    else didMount.current = true;
  }, [gender, greenTvet, activityType]);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            { state !== 'DELETED' &&
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              Try to add a new activity!
            </Typography>
            }
            { state === 'NEW' && authState.role !== 'ROLE_READER' &&
            <AddActivityDialog classes={classes} confirmationHandler={addActivityConfirmationHandler}/>
            }
          </div>
      );
    }

    if (isLoading || !items) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{width: '95%'}}>
            <TextField
                label="Name, type, date"
                id="activity-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
                InputProps={{
                  endAdornment:
                  <InputAdornment position="end">
                    <SelectActivityFilterDialog
                      confirmationHandler={confirmApplyFilterHandler}
                      clearFilterHandler={confirmClearFilterHandler} />
                  </InputAdornment>,
                }}
            />
          </div>
          <TableContainer style={{width: '95%'}} component={Paper} className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell />
                  <TableCell>Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Indicator attribution</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).map((row, idx) => (
                    <Row
                        key={row.id}
                        activity={row}
                        fetchData={loadItems}
                        shouldOpen={items.length === 1}
                        removeHandler={removeActivity}
                        state={state}
                        obsrv={idx === items.length - 1 ? observe : null}
                        isAppUser={authState.role === 'ROLE_APP'}
                        isSuperUser={authState.role === 'ROLE_SUPER'}
                        isReadOnlyUser={authState.role === 'ROLE_READER'}
                    />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          { state === 'ALL' && authState.role === 'ROLE_SUPER' &&
            <>
              { !sector &&
                <div hidden={authState && authState.role && authState.role !== 'ROLE_SUPER'}>
                  <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => fetchDump()}
                      className={classes.fab}
                      style={{position: 'fixed', marginBottom: '0.075rem', marginRight: '14rem' }}
                  >
                    <DownloadIcon/>
                  </Fab>
                </div>
              }
              <AddActivityDialog classes={classes} confirmationHandler={addActivityConfirmationHandler}/>
            </>
          }
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Activities);