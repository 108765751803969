import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, withStyles} from '@mui/styles';
import {
  getActivity, searchSectors,
  updateActivity
} from "../../utils/api";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import {formatTime} from '../../utils/timeHelper';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import EditableInput from "../input/EditableInput";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {Checkbox, CircularProgress, FormGroup} from "@mui/material";
import {
  FormControlLabel,
  ImageList,
} from "@mui/material";
import {useParams} from "react-router-dom";

const styles = () => ({
  contentWrapper: {
    margin: 'auto'
  },
  tableContainer: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
    overflow: 'hidden',
  },
  tableHead: {
    background: '#F5F5F5',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function Activity() {
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [stateActivity, setStateActivity] = useState(null);
  const [activityBuffer, setActivityBuffer] = useState(null);

  let {activityId} = useParams();

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getActivity(activityId);
          const activityData = result.data;

          if (!cancelRequest.current) {
            if (activityData) {
              setIsLoading(false);
              setStateActivity(activityData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  const handleCompletionStatusChange = (status) => {
    updateActivityDataAndDispatch('completed', status);
  };

  const handleIsGenderChange = (status) => {
    updateActivityDataAndDispatch('isGender', status);
  };

  const handleIsGreenTvtChange = (status) => {
    updateActivityDataAndDispatch('isGreenTvt', status);
  };

  const updateActivityDataAndDispatch = async (field, value) => {
    try {
      setActivityBuffer({
        ...activityBuffer,
        [field]: value,
      });
      const { data: activityData } = await updateActivity(
          stateActivity.id,
          { [field]: value }
      );

      if (!cancelRequest.current) {
        setStateActivity(activityData);
      }
    } catch (error) {
      setActivityBuffer(activityBuffer);
    }
  };

  const handleActivityTypeChange = async (event) => {
    await updateActivityDataAndDispatch('activityType', event.target.value);
    await fetchData(true);
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Access denied!
            </div>
          </>
      );
    }

    if (isLoading || !stateActivity) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary"/>
            </div>
          </>
      );
    }

    return <React.Fragment>
            <Paper elevation={0} variant="outlined" className={classes.paper}>
              <AppBar className={classes.toolbar}
                      position="static"
                      color="default"
                      elevation={0}>
                <Toolbar>
                  <Typography variant="h6" gutterBottom component="div">
                    1. General information
                  </Typography>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                <ImageList cellHeight={'auto'} className={classes.gridList}
                           cols={2}>
                  <DescriptionList>
                    <DescriptionTerm>Type</DescriptionTerm>
                    <Description>
                      <Select
                          labelId="activity-type-select"
                          id="activity-type-select"
                          value={stateActivity.activityType}
                          className={classes.selectEmpty}
                          onChange={handleActivityTypeChange}
                      >
                        <MenuItem value={'COOPERATIVE_TRAINING'}>Cooperative Training</MenuItem>
                        <MenuItem value={'SHORT_TERM_TRAINING'}>Short-term Training</MenuItem>
                        <MenuItem value={'FURTHER_TRAINING'}>Further Training</MenuItem>
                        <MenuItem value={'LEADERSHIP_TRAINING'}>Leadership Training</MenuItem>
                        <MenuItem value={'PUBLIC_PRIVATE_DIALOGUE'}>Public Private Dialogue</MenuItem>
                        <MenuItem value={'POLICY_SUPPORT'}>Policy Support</MenuItem>
                        <MenuItem value={'COMPANY_SUPPORT'}>Company Support</MenuItem>
                        <MenuItem value={'DIGITALIZATION'}>Digitalization</MenuItem>
                        <MenuItem value={'OTHER'}>Other</MenuItem>
                      </Select>
                    </Description>
                  </DescriptionList>
                </ImageList>
                <ImageList cellHeight={'auto'} className={classes.gridList}
                           cols={2}>
                  <DescriptionList>
                    <FormGroup>
                      <FormControlLabel
                          sx={{ alignItems: 'flex-start', marginTop: 2 }}
                          control={
                            <Checkbox
                                checked={stateActivity.isGender}
                                color="primary"
                                sx={{
                                  marginTop: -1.6,
                                }}
                                onChange={(e) => {
                                  let checked = e.target.checked;
                                  handleIsGenderChange(checked);
                                }
                                } />}
                          label="Gender" />
                    </FormGroup>
                  </DescriptionList>
                  <br/>
                  <DescriptionList>
                    <FormGroup>
                      <FormControlLabel
                          sx={{ alignItems: 'flex-start', marginTop: 2 }}
                          control={
                            <Checkbox
                                checked={stateActivity.isGreenTvt}
                                color="primary"
                                sx={{
                                  marginTop: -1.6,
                                }}
                                onChange={(e) => {
                                  let checked = e.target.checked;
                                  handleIsGreenTvtChange(checked);
                                }
                                } />}
                          label="Green TVT" />
                    </FormGroup>
                  </DescriptionList>
                  <br/>
                  <DescriptionList>
                    <DescriptionTerm>Name</DescriptionTerm>
                    <Description>
                      <EditableInput
                          alignWithBigRow
                          name="name"
                          value={stateActivity.name}
                          disabled={false}
                          fullWidth={true}
                          size={'large'}
                          multiline={true}
                          rows={4}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Planned start date</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="plannedDate"
                          type="date"
                          value={formatTime(stateActivity.plannedDate, 'dd.LL.yyyy')}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                                   value) => updateActivityDataAndDispatch(fieldName,
                              value + ' 00:00:00.000')}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Planned end date</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="plannedEndDate"
                          type="date"
                          value={formatTime(stateActivity.plannedEndDate,
                              'dd.LL.yyyy')}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                                   value) => updateActivityDataAndDispatch(fieldName,
                              value + ' 00:00:00.000')}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Start date</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="date"
                          type="date"
                          value={formatTime(stateActivity.date, 'dd.LL.yyyy')}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value + ' 00:00:00.000')}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>End date</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="endDate"
                          type="date"
                          value={formatTime(stateActivity.endDate,
                              'dd.LL.yyyy')}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value + ' 00:00:00.000')}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Product owner</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="moderator"
                          value={stateActivity.moderator}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Responsible team</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="organizer"
                          value={stateActivity.organizer}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Implementing party</DescriptionTerm>
                    <Description>
                      <EditableInput
                          alignWithBigRow
                          name="implementingParty"
                          value={stateActivity.implementingParty}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Sector</DescriptionTerm>
                    <Description>
                      <BasicAutocomplete multiple handleChange={(value) => updateActivityDataAndDispatch('sectors', value)} searchFct={searchSectors} currentValue={stateActivity.sectors} property={'sector'} />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Comments</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="comment"
                          value={stateActivity.comment}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                                   value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <br/>
                  <DescriptionList>
                    <FormGroup>
                      <FormControlLabel
                          sx={{ alignItems: 'flex-start', marginTop: 2 }}
                          control={
                            <Checkbox
                                checked={stateActivity.completed}
                                color="primary"
                                sx={{
                                  marginTop: -1.6,
                                }}
                                onChange={(e) => {
                                  let checked = e.target.checked;
                                  handleCompletionStatusChange(checked);
                                }
                                } />}
                          label="Activity complete" />
                    </FormGroup>
                  </DescriptionList>
                </ImageList>
              </div>
            </Paper>
      <Paper elevation={0} variant="outlined" className={classes.paper}>
        <AppBar className={classes.toolbar}
                position="static"
                color="default"
                elevation={0}>
          <Toolbar>
            <Typography variant="h6" gutterBottom component="div">
              2. Participant information
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <ImageList cellHeight={'auto'} className={classes.gridList} cols={3}>
            <DescriptionList>
              <DescriptionTerm>Total number of participants</DescriptionTerm>
              <Description>
                <EditableInput
                    name="numberParticipants"
                    value={stateActivity.numberParticipants}
                    disabled={false}
                    fullWidth={true}
                    onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                />
              </Description>
            </DescriptionList>
            <DescriptionList>
              <DescriptionTerm>Number of women</DescriptionTerm>
              <Description>
                <EditableInput
                    name="numberFemaleParticipants"
                    value={stateActivity.numberFemaleParticipants}
                    disabled={false}
                    fullWidth={true}
                    onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                />
              </Description>
            </DescriptionList>
          </ImageList>
        </div>
      </Paper>
    </React.Fragment>
  }

  return renderData();

}

export default withStyles(styles)(Activity);