import React from "react";

export function formatActivityType(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'COOPERATIVE_TRAINING': return 'Cooperative Training';
    case 'SHORT_TERM_TRAINING': return 'Short-term Training';
    case 'FURTHER_TRAINING': return 'Further Training';
    case 'LEADERSHIP_TRAINING': return 'Leadership Training';
    case 'PUBLIC_PRIVATE_DIALOGUE': return 'Public Private Dialogue';
    case 'POLICY_SUPPORT': return 'Policy Support';
    case 'COMPANY_SUPPORT': return 'Company Support';
    case 'DIGITALIZATION': return 'Digitalization';
    case 'OTHER': return 'Other';
    default:
      return '';
  }
}

export function getInTextActivityType(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'COOPERATIVE_TRAINING': return 'cooperative training';
    case 'SHORT_TERM_TRAINING': return 'short-term training';
    case 'FURTHER_TRAINING': return 'further training';
    case 'LEADERSHIP_TRAINING': return 'leadership training';
    case 'PUBLIC_PRIVATE_DIALOGUE': return 'public private dialogue';
    case 'POLICY_SUPPORT': return 'policy support';
    case 'COMPANY_SUPPORT': return 'company support';
    case 'DIGITALIZATION': return 'digitalization';
    case 'OTHER': return 'other';
    default:
      return '';
  }
}