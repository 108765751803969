import React from "react";

export function formatSector(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'AUTOMOTIVE': return 'Automotive';
    case 'ELECTROTECHNOLOGY': return 'Electrotechnoloy';
    case 'HOTEL_AND_TOURISM': return 'Hotel and Tourism';
    case 'CONSTRUCTION': return 'Construction';
    case 'AGRO_PROCESSING': return 'Agro Processing';
    case 'HEALTH': return 'Health';
    default:
      return '';
  }
}