import React from "react";
import { withStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const styles = () => ({
  contentWrapper: {
    width: '90%',
    margin: 'auto',
    marginLeft: 'auto',
    textAlign: 'left',
  },
  paper: {
    padding: '2rem',
  },
});

function DataProtection(props) {
  const { classes } = props;

  return (
      <>
        <div className={classes.contentWrapper}>
          <Paper elevation={3} className={classes.paper}>
            <Typography color="inherit" variant="h5" component="h5">
              Datenschutzerklärung
            </Typography>
            <br/>
            <Typography color="inherit" variant="body1" component="div">
              Der Deutschen Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH ist ein verantwortungsbewusster und transparenter Umgang mit personenbezogenen Daten sehr wichtig.

              Im Folgenden finden Nutzerinnen und Nutzer Informationen darüber,<br/>

              - welche Kontaktmöglichkeiten zum Thema Datenschutz bei der GIZ bestehen.<br/>
              - welche Daten beim Besuch der Website verarbeitet werden.<br/>
              - welche Daten verarbeitet werden, wenn Nutzer*innen mit uns in Kontakt treten, Newsletter oder Pressemeldungen abonnieren oder weitere Onlineangebote der GIZ nutzen.<br/>
              - welche Widerspruchsmöglichkeiten gegen die Speicherung der Daten bestehen.<br/>
              - welche Rechte uns gegenüber bestehen.<br/>
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Verantwortlicher und Datenschutzbeauftragter
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Verantwortliche Stelle für die Datenverarbeitung ist die Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH.<br/>
              <br/>
              Anschrift:<br/>
              Friedrich-Ebert-Allee 32 + 36, 53113 Bonn<br/>
              Dag-Hammarskjöld-Weg 1–5, 65760 Eschborn<br/>
              <br/>
              Kontakt:<br/>
              <a href="mailto:info@giz.de">info@giz.de</a><br/>
              <br/>
              Bei konkreten Fragen zum Schutz Ihrer Daten wenden Sie sich bitte an den Datenschutzbeauftragten der GIZ: <a href="mailto:datenschutzbeauftragter@giz.de">datenschutzbeauftragter@giz.de</a>


            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Information über die Erhebung personenbezogener Daten
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Allgemein<br/>
              Die GIZ verarbeitet personenbezogene Daten ausschließlich in Übereinstimmung mit der Europäischen Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG).
              Personenbezogene Daten sind zum Beispiel Name, Adresse, E-Mail-Adressen und Nutzerverhalten.<br/>
              Personenbezogene Daten werden von der GIZ nur im notwendigen Umfang verarbeitet. Welche Daten zu welchem Zweck und auf welcher Grundlage benötigt und verarbeitet werden, richtet sich maßgeblich nach der Art der Leistung, die von Ihnen in Anspruch genommen wird, beziehungsweise ist abhängig davon, für welchen Zweck diese benötigt werden.<br/>
              <br/>
              Erhebung personenbezogener Daten bei Besuch unserer Webseite<br/>
              Beim Besuch der GIZ-Webseite übermittelt der genutzte Browser automatisch Daten, die in einer Protokolldatei gespeichert werden. Die GIZ selbst verarbeitet nur diejenigen Daten, die technisch erforderlich sind, um die Webseite korrekt anzuzeigen und die Stabilität und Sicherheit zu gewährleisten.
              Unter anderem werden über jeden Zugriff gespeichert, welche Seite aufgerufen wird, welche IP Adresse das zugreifende Gerät hat, von welcher Seite der/die Nutzer*in weitergeleitet wurde sowie Datum und Uhrzeit des Abrufs. Eine detaillierte Liste der gespeicherten Daten finden Sie hier.
              Die Daten in der Protokolldatei werden nach fünf Tagen gelöscht.<br/>
              <br/>
              Cookies<br/>
              Bei Besuch der GIZ-Webseite werden kleine Textdateien, sogenannte Cookies, auf dem Rechner des Besuchers beziehungsweise der Besucherin gespeichert. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu gestalten. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen.<br/>
              Auf der Webseite der GIZ werden Cookies verwendet, die automatisch gelöscht werden, sobald der Browser in dem die Seite angezeigt wird, geschlossen wird (sog. temporäre Cookies bzw. Session-Cookies.) Durch diese Art von Cookies ist es möglich, verschiedene Anfragen eines Browsers einer Sitzung zuordnen und es ist möglich, den Browser bei erneuten Webseitenbesuchen wiederzuerkennen (Session-ID).<br/>
              <br/>
              Analysedienst etracker (Nutzeranalyse)<br/>
              Die GIZ verwendet auf ihrer Webseite zur Analyse von Nutzungsdaten den Webanalysedienst „etracker“, welcher von der etracker GmbH aus Hamburg betrieben wird. Die Daten werden vollständig anonym gespeichert und ausgewertet.<br/>
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Verarbeitung personenbezogener Daten bei Kontaktaufnahme
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Bei der Kontaktaufnahme durch Nutzerinnen und Nutzen werden die angegebenen Daten verarbeitet, um die Anfrage bearbeiten zu können. Es bestehende folgende Kontaktmöglichkeiten:<br/>

              - E-Mail<br/>
              - Telefon<br/>
              - Brief<br/>
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Informationsbereitstellung
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Auf der GIZ-Webseite werden Newsletter und ein Presse-Abo angeboten. Die Verarbeitung der personenbezogenen Daten ist abhängig von der Art der Informationsbereitstellung.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Weitere Webangebote
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Beim Aufruf weiterer Webangebote der GIZ, zum Beispiel Stellenbewerbungen oder Anmeldungen zu Fortbildungen, ist die Eingabe personenbezogener Daten für die weitere Verarbeitung erforderlich. Hier gelten die auf das jeweilige Angebot angepassten Datenschutzerklärungen.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Verarbeitung personenbezogener Daten im Rahmen der Nutzung sozialer Netzwerke
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Die GIZ bietet auf ihrer Website die Möglichkeit an, Unternehmensauftritte in sozialen Netzwerken und Plattformen wie Twitter, LinkedIn, YouTube und Facebook zu besuchen.
              Diese Onlinepräsenzen werden betrieben, um mit den dort aktiven Nutzern zu interagieren und über Projekte und Leistungen zu informieren. Ein Klick auf das Logo eines sozialen Netzwerks, führt zum GIZ-Auftritt im jeweiligen Netzwerk.
              Bei Besuch der Plattformen werden keine personenbezogenen Daten durch die GIZ, jedoch durch die Betreiber des jeweiligen sozialen Netzwerks erhoben, genutzt und gespeichert. Dies geschieht auch dann, wenn Besucherinnen und Besucher selbst kein Profil in dem jeweiligen sozialen Netzwerk haben.
              Die einzelnen Datenverarbeitungsvorgänge und ihr Umfang unterscheiden sich je nach Betreiber des jeweiligen sozialen Netzwerks. Die GIZ hat keinen Einfluss auf die Datenerhebung und deren weitere Verwendung durch die Betreiber der sozialen Netzwerke. In welchem Umfang, an welchem Ort und für welche Dauer die Daten gespeichert werden, inwieweit die Netzwerke bestehenden Löschpflichten nachkommen, welche Auswertungen und Verknüpfungen mit den Daten vorgenommen werden und an wen die Daten weitergegeben werden, ist für uns nicht umfassend erkennbar.
              Bei dem Aufruf eines GIZ-Auftritts in den sozialen Medien gelten die Nutzungsbedingungen und die Datenschutzerklärungen der jeweiligen Betreiber. Die Kontaktadressen und die Links auf die Datenschutzerklärungen der sozialen Medien, in denen die GIZ Auftritte betreibt, stellt die GIZ zu Verfügung.

              Mehr Informationen <a href="https://www.giz.de/de/html/89811.html">hier</a>.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Weitergabe an Dritte
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Die GIZ gibt personenbezogene Daten nicht an Dritte weiter, es sei denn, sie ist durch gesetzliche Bestimmungen rechtlich dazu verpflichtet oder berechtigt.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Datenübermittlungen ins Ausland
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Eine Übermittlung von personenbezogenen Daten in Drittstaaten durch die GIZ findet nicht statt. Bei der Nutzung sozialer Medien gelten die Datenschutzbestimmungen der jeweiligen Anbieter.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Dauer Datenspeicherung
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Daten von Nutzerinnen und Nutzern werden nicht länger aufbewahrt, als dies für den Zweck, zu dem diese verarbeitet werden, oder aufgrund von gesetzlichen Aufbewahrungspflichten erforderlich ist.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              IT-Sicherheit von Nutzerdaten
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Der Schutz von personenbezogenen Daten ist der GIZ ein wichtiges Anliegen. Deshalb sorgen technische und organisatorische Sicherheitsmaßnahmen dafür, dass die Daten gegen zufällige und vorsätzliche Manipulationen, versehentliche Löschung sowie unberechtigten Zugriff geschützt werden. Diese Maßnahmen werden den technischen Entwicklungen entsprechend aktualisiert und den Risiken stetig angepasst.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Hinweis auf Rechte der Nutzer
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Als Besucherinnen und Besucher der GIZ-Webseite haben Sie das Recht,<br/>
              <br/>
              - Auskunft über bei uns gespeicherte Daten zu Ihrem Besuch zu erhalten (Art. 15 DSGVO),<br/>
              - Berichtigung bei uns gespeicherter Daten zu Ihrem Besuch zu fordern (Art. 16 DSGVO),<br/>
              - Löschung bei uns gespeicherter Daten zu Ihrem Besuch zu beantragen (Art. 17 DSGVO).<br/>
              - Einschränkung der Verarbeitung bei uns gespeicherter Daten zu Ihrem Besuch verlangen (Art. 18 DSGVO),<br/>
              - Widerspruch gegen die Speicherung der Daten zu Ihrem Besuch einzulegen, sofern personenbezogene Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f und e DSGVO verarbeitet werden (Art. 21 DSGVO),<br/>
              - Widerspruch gegen die Speicherung der Daten zu Ihrem Besuch einzulegen, sofern personenbezogene Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f und e DSGVO verarbeitet werden (Art. 21 DSGVO),<br/>
              - Ihren Besuch betreffende Daten in einem gängigen, maschinenlesbaren Format vom Verantwortlichen zu erhalten, um diese ggf. an einen anderen Verantwortlichen weiterleiten zu lassen. (Recht auf Datenübertragbarkeit, Art. 20 DSGVO).<br/>
              - auf Widerruf Ihrer Einwilligung, sofern die Verarbeitung der Daten zu Ihrem Besuch auf Grundlage einer Einwilligung (Art. 6 Abs. 1 a) DSGVO) erfolgt ist. Die Rechtmäßigkeit der Verarbeitung aufgrund der getätigten Einwilligung bleibt bis zum Eingang des Widerrufs unberührt.<br/>
              <br/>
              Nutzer*innen haben darüber hinaus gemäß Art. 77 DSGVO das Recht, Beschwerde bei der zuständigen Datenschutzaufsichtsbehörde einzulegen. Zuständige Behörde ist der Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI).<br/>
              <br/>
              <br/>
              Stand: Oktober 2020
            </Typography>
          </Paper>
        </div>
      </>
  );
}

export default withStyles(styles)(DataProtection);