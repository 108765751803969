import React, {useContext} from 'react';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl, FormControlLabel, FormGroup,
  IconButton,
  TextField,
  Tooltip
} from "@mui/material";
import {withStyles} from "@mui/styles";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddLocationDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [name, setName] = React.useState('');
  const [lat, setLat] = React.useState('');
  const [lng, setLng] = React.useState('');
  const [isHealth, setIsHealth] = React.useState(false);
  const [isTvet, setIsTvet] = React.useState(false);
  const [isAppliedSciences, setIsAppliedSciences] = React.useState(false);
  const [isAgro, setIsAgro] = React.useState(false);
  const [isGizOffice, setIsGizOffice] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setOpen(true);
    setName(entity && entity.englishName ? entity.englishName : '');
    setLat(entity && entity.lat ? entity.lat : '');
    setLng(entity && entity.lng ? entity.lng : '');
    setIsHealth(entity && entity.hasHealth ? entity.hasHealth : false);
    setIsTvet(entity && entity.hasTvet ? entity.hasTvet : false);
    setIsAppliedSciences(entity && entity.hasAppliedSciences ? entity.hasAppliedSciences : false);
    setIsAgro(entity && entity.hasAgro ? entity.hasAgro : false);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setLat('');
    setLng('');
    setIsHealth(false);
    setIsTvet(false);
    setIsAppliedSciences(false);
    setIsAgro(false);
  };

  const handleConfirm = () => {
    action === 'create' ?
      confirmationHandler(name, lat, lng, isHealth, isTvet, isAppliedSciences, isAgro, isGizOffice) :
        confirmationHandler(entity.id, name, lat, lng, isHealth, isTvet, isAppliedSciences, isAgro, isGizOffice);
    handleClose();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLatChange = (event) => {
    setLat(event.target.value);
  };

  const handleLngChange = (event) => {
    setLng(event.target.value);
  };

  return (
      <div>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon sx={{ fontSize: 24 }}/>
            Add location
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon sx={{ fontSize: 24, color: 'black' }} />
            </IconButton>
          </Tooltip>
        }
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action !== 'edit' ? 'Add' : 'Edit'} location</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the location
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    label="Name"
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus={false}
                    margin="normal"
                    id="lat"
                    label="Latitude"
                    type="number"
                    value={lat}
                    inputProps={{
                      step: "0.01"
                    }}
                    onChange={handleLatChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus={false}
                    margin="normal"
                    id="lng"
                    label="Longitude"
                    type="number"
                    value={lng}
                    inputProps={{
                      step: "0.01"
                    }}
                    onChange={handleLngChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <DialogContentText>
              Which types of institutions are located here?
            </DialogContentText>
            <div>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={isHealth}
                          color="primary"
                          onChange={(e) => {
                            let checked = e.target.checked;
                            setIsHealth(checked);
                            if (!checked) {
                              setIsHealth(false);
                            }
                          }
                          } />}
                    label="Health" />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={isTvet}
                          color="primary"
                          onChange={(e) => {
                            let checked = e.target.checked;
                            setIsTvet(checked);
                            if (!checked) {
                              setIsTvet(false);
                            }
                          }
                          } />}
                    label="TVET" />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={isAppliedSciences}
                          color="primary"
                          onChange={(e) => {
                            let checked = e.target.checked;
                            setIsAppliedSciences(checked);
                            if (!checked) {
                              setIsAppliedSciences(false);
                            }
                          }
                          } />}
                    label="University of Applied Sciences" />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={isAgro}
                          color="primary"
                          onChange={(e) => {
                            let checked = e.target.checked;
                            setIsAgro(checked);
                            if (!checked) {
                              setIsAgro(false);
                            }
                          }
                          } />}
                    label="Agro Processing" />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={isGizOffice}
                          color="primary"
                          onChange={(e) => {
                            let checked = e.target.checked;
                            setIsGizOffice(checked);
                            if (!checked) {
                              setIsGizOffice(false);
                            }
                          }
                          } />}
                    label="GIZ Office" />
              </FormGroup>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action !== 'edit' ? 'Create' : 'Edit'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddLocationDialog)