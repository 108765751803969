import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import Navigator from './Navigator';
import ProtectedRoute from "./auth/ProtectedRoute";
import Dashboard from "./dashboard/Dashboard";
import Header from "./Header";
import UserSettings from "./settings/UserSettings";
import {ThemeProvider, withStyles} from "@mui/styles";
import {
  createTheme,
  CssBaseline,
  Hidden,
  Typography
} from "@mui/material";
import Indicators from "./indicators/Indicators";
import TabbedOutputIndicators from "./indicators/TabbedOutputIndicators";
import DataProtection from "./DataProtection";
import Imprint from "./Imprint";
import Activity from "./activities/Activity";
import LocationSettings from "./settings/LocationSettings";
import PartnerSettings from "./settings/PartnerSettings";
import TabbedActivities from "./activities/TabbedActivities";
import InstitutionSettings from "./settings/InstitutionSettings";
import ProjectOutcome from "./dashboard/ProjectOutcome";

function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        <a href={'/imprint'}>Imprint</a> | <a href={'/data-protection'}>Data protection policy</a>
      </Typography>
  );
}

let theme = createTheme({
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(2, 1),
    background: '#eaeff1',
  },
  mainTabbed: {
    flex: 1,
    background: '#eaeff1',
    padding: 0,
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};

function Paperbase(props) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const query = new URLSearchParams(document.location.search);

  return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.app}>
            <BrowserRouter>
              <Switch>
                <ProtectedRoute exact path={["/dashboard", "/"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline="Dashboard"
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <Dashboard />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/project-outcome"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Project Outcome"}
                          showNavBar={false}
                          elevate={0} />
                  <main className={classes.mainTabbed}>
                    <ProjectOutcome />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/activities/log/:tab"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Activities"}
                          showNavBar={false}
                          elevate={0} />
                  <main className={classes.mainTabbed}>
                    <TabbedActivities state={'ALL'} />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/activities/planned/:tab"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Planned activities"}
                          showNavBar={false}
                          elevate={0} />
                  <main className={classes.mainTabbed}>
                    <TabbedActivities state={'PLANNED'} />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/activity/:activityId"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Activities"}
                          showNavBar={false} />
                  <main className={classes.main}>
                    <Activity/>
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/indicators/standard"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Standard indicator"}
                          showNavBar={false}
                          elevate={0} />
                  <main className={classes.mainTabbed}>
                    <Indicators indicatorType="STANDARD" />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/indicators/outcome"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Outcome indicators"}
                          showNavBar={false}
                          elevate={0} />
                  <main className={classes.mainTabbed}>
                    <Indicators indicatorType="OUTCOME" />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/indicators/output"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Output indicators"}
                          showNavBar={false}
                          elevate={0} />
                  <main className={classes.mainTabbed}>
                    <TabbedOutputIndicators />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/users"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Users"}
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <UserSettings />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/locations"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Locations"}
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <LocationSettings />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/institutions"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Institutions"}
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <InstitutionSettings />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/partners"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Partners"}
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <PartnerSettings />
                  </main>
                </ProtectedRoute>
                <Route exact path={["/imprint"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Imprint"}
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <Imprint />
                  </main>
                </Route>
                <Route exact path={["/data-protection"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Data Protection Policy"}
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <DataProtection />
                  </main>
                </Route>
                <Route path='/authentication' component={() => {
                  window.location.href =
                      `${process.env.REACT_APP_FIREBASE_REDIRECT_URL}?mode=${query.get('mode')}&oobCode=${query.get('oobCode')}&apiKey=${query.get('apiKey')}&lang=${query.get('lang')}`;
                  return null;
                }}/>
              </Switch>
            </BrowserRouter>
            <footer className={classes.footer}>
              <Copyright />
            </footer>
          </div>
        </div>
      </ThemeProvider>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);