import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Avatar,
  Chip,
  Tooltip
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AttachmentIcon from "@mui/icons-material/Attachment";
import {download} from "../../utils/api";
import FileDownload from "js-file-download";

export default function AttachmentHandlerDialog(props) {
  const { attachments } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <div>
        <Tooltip title={`${attachments.length} attached ${attachments.length > 1 ? 'files' : 'file'}`}>
          <IconButton aria-label="attachments"
                      onClick={handleClickOpen}>
            <AttachmentIcon />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Attached files</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The following {attachments.length > 1 ? attachments.length + ' files have' : 'file has'} been attached to this indicator value
            </DialogContentText>
            { attachments && attachments.length > 0 && attachments.map(file => (
                <Chip onClick={() => download(file.uuid).then((response) => {
                  FileDownload(response.data, file.filename);
                })} sx={{margin: 1}} avatar={<Avatar>{file.filename.slice(file.filename.lastIndexOf('.') + 1)}</Avatar>} label={file.filename} />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" size="small">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}