import React, {useState} from 'react';
import {AppBar, CircularProgress, Tab, Tabs} from '@mui/material';
import {withStyles} from "@mui/styles";
import PropTypes from "prop-types";
import Activities from "./Activities";
import {formatSector} from "../../utils/sectorHelper";
import {useHistory} from "react-router-dom";

const styles = (theme) => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
  main: {
    flex: 1,
    padding: theme.spacing(3, 3),
    background: '#eaeff1',
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{height: '100%'}}
          {...other}
      >
        {value === index && (
            <div style={{height: '100%'}}>{children}</div>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabbedActivities(props) {
  const { classes, state } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [sectors, setSectors] = useState([
    'AUTOMOTIVE', 'ELECTROTECHNOLOGY', 'HOTEL_AND_TOURISM', 'CONSTRUCTION',
      'AGRO_PROCESSING', 'HEALTH'
  ]);
  const [value, setValue] = useState(0);

  let history = useHistory();

  const handleTabChange = (event, newValue) => {
    history.push(`${state === 'ALL' ? '/activities/log/' : '/activities/planned/'}${newValue}`);
    setValue(newValue);
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <AppBar
              component="div"
              className={classes.appBar}
              color="primary"
              position="static"
              elevation={5}
          >
            <Tabs value={value} textColor={'#fff'} TabIndicatorProps={{style: {background:'white'}}} onChange={handleTabChange}>
              <Tab style={{fontSize: 14, fontWeight: "normal"}} key={0} label={'All'} {...a11yProps(0)}/>
              { sectors.map((sector, idx ) => (
                  <Tab style={{fontSize: 14, fontWeight: "normal"}} key={idx + 1} label={formatSector(sector)} value={idx + 1} {...a11yProps(idx + 1)}/>
              ))}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <main className={classes.main}>
              <Activities state={state} />
            </main>
          </TabPanel>
          { sectors.map((sector, idx) => (
              <TabPanel key={idx + 1} value={value} index={idx + 1}>
                <main className={classes.main}>
                  <Activities sector={sector} state={state} />
                </main>
              </TabPanel>
          ))}
        </>

    );
  };

  return renderData();
}

export default withStyles(styles)(TabbedActivities);